@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');


* {
    font-family: "Inter Tight", sans-serif;
}

body {
    background-color: #222222;
}